import { combineReducers } from 'redux';
import LoginReducer from '../pages/Login/reducer';
import LayoutReducer from '../pages/Layout/reducer';
import ReportReducer from '../pages/Report/reducer';

export default combineReducers({
  LayoutReducer,
  LoginReducer,
  ReportReducer
});
