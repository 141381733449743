import { ActionTypes } from './actions';

const DEFAULT_STATE = {
  reports: {
    listReports: [],
    totalAmount: 0,
    totalRecords: 0,
  },
  checkClientAllocation: {
    result: null,
  },
  exnessToken: {},
  listExnessToken: [],
  state: "",
  reportFilter: {},
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    // get list reports actions
    case ActionTypes.GET_LIST_REPORTS_SUCCESS:
      return {
        ...state,
        reports: action.payload,
        state: action.type
      };
    case ActionTypes.GET_LIST_REPORTS_ERROR:
      return {
        ...state,
        reports: action.payload,
        state: action.type
      };
    // get report filter actions
    case ActionTypes.GET_REPORT_FILTER_SUCCESS:
      return {
        ...state,
        reportFilter: action.payload,
        state: action.type
      };
    case ActionTypes.GET_REPORT_FILTER_ERROR:
      return {
        ...state,
        reportFilter: action.payload,
        state: action.type
      };
    // check client allocation actions
    case ActionTypes.CHECK_CLIENT_ALLOCATION_SUCCESS:
      return {
        ...state,
        checkClientAllocation: action.payload,
        state: action.type
      };
    case ActionTypes.CHECK_CLIENT_ALLOCATION_ERROR:
      return {
        ...state,
        checkClientAllocation: action.payload,
        state: action.type
      };
    // add exness token actions
    case ActionTypes.ADD_EXNESS_TOKEN_SUCCESS:
      return {
        ...state,
        exnessToken: action.payload,
        state: action.type
      };
    case ActionTypes.ADD_EXNESS_TOKEN_ERROR:
      return {
        ...state,
        exnessToken: action.payload,
        state: action.type
      };
    // get exness token actions
    case ActionTypes.GET_EXNESS_TOKEN_SUCCESS:
      return {
        ...state,
        listExnessToken: action.payload,
        state: action.type
      };
    case ActionTypes.GET_EXNESS_TOKEN_ERROR:
      return {
        ...state,
        exnessToken: action.payload,
        state: action.type
      };
    default:
      return {
        ...state,
        state: action.type
      };
  }
};
