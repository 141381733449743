import React, { useState, useEffect } from 'react';
import { Button, Input, } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actions, ActionTypes } from '../actions';

const initParams = {
  username: null,
  token: null
};

function ExnessTokens({ curTab }) {
  const dispatch = useDispatch();

  const [params, setParams] = useState(initParams);
  const state = useSelector((state) => state.ReportReducer?.state);

  useEffect(() => {
    if (state === ActionTypes.ADD_EXNESS_TOKEN_SUCCESS) {
      setParams(initParams);
    }
  }, [state]);

  const onSearch = () => {
    dispatch(actions.addExnessTokenStart({...params}));
  };

  const handleSearchChange = (key, value) => {
    setParams({ ...params, [key]: value.target.value });
  };

  return (
    <div className="exness-token">
      <div className="exness-token-title">Add Exness Token</div>
      <div className="exness-token-username">
        <div className="exness-token-username-title">Username</div>
        <Input
          value={params.username}
          allowClear
          placeholder="username"
          maxLength={64}
          onPressEnter={onSearch}
          onChange={(value) => handleSearchChange('username', value)} 
        />
      </div>
      <div className="exness-token-token">
        <div className="exness-token-token-title">Token</div>
        <Input
          value={params.token}
          allowClear
          placeholder="token"
          maxLength={2048}
          onPressEnter={onSearch}
          onChange={(value) => handleSearchChange('token', value)}
        />
      </div>
      <Button type="primary" className="exness-token-add-button" onClick={onSearch}>
        Add
      </Button>
    </div>
  );
}

export default ExnessTokens;
