import { put, takeLatest } from 'redux-saga/effects';
import { actions, ActionTypes } from './actions';
import {
  postLoginAPI,
  postConfirmEmailAPI,
  postChangePasswordAPI,
  postResetPasswordAPI,
} from '../../services/AuthRequest';
import { actions as layoutActions } from '../Layout/actions';
import showMessage from '../../components/Messsage';
import { MESSAGE, META_CODE, typeOfAntDesign } from '../../common';

function* postLogin(action) {
  try {
    const response = yield postLoginAPI(action.body);
    console.log('response', response);
    if (response?.data?.accessToken) {
      yield put(actions.postLoginSuccess(response?.data));
    } else {
      yield put(actions.postLoginError({}));
    }
  } catch (e) {
    yield put(actions.postLoginError(e));
  }
}

function* postChangePassword(action) {
  try {
    const response = yield postChangePasswordAPI(action.body);
    if (response.meta.code === META_CODE.SUCCESS) {
      yield put(actions.postChangePasswordSuccess());
      yield showMessage(typeOfAntDesign.SUCCESS, MESSAGE.UPDATE_SUCCESS);
    } else {
      yield put(actions.postChangePasswordError());
    }
  } catch (e) {
    yield put(actions.postChangePasswordError(e));
  }
}

function* postConfirmEmail(action) {
  yield put(layoutActions.showLoading());
  try {
    const response = yield postConfirmEmailAPI(action.body);
    if (response.meta.code === META_CODE.SUCCESS) {
      yield put(actions.postConfirmEmailSuccess());
    } else {
      yield put(actions.postConfirmEmailError());
    }
  } catch (e) {
    yield put(actions.postConfirmEmailError(e));
  }
}

function* postResetPassword(action) {
  try {
    const response = yield postResetPasswordAPI(action.body);
    if (response.meta.code === META_CODE.SUCCESS) {
      yield put(actions.postResetPasswordSuccess());
    } else {
      yield put(actions.postResetPasswordError());
    }
  } catch (e) {
    yield put(actions.postResetPasswordError(e));
  }
}

export function* watchLogin() {
  yield takeLatest(ActionTypes.POST_LOGIN_START, postLogin);
  yield takeLatest(ActionTypes.POST_CHANGE_PASSWORD_START, postChangePassword);
  yield takeLatest(ActionTypes.POST_CONFIRM_EMAIL_START, postConfirmEmail);
  yield takeLatest(ActionTypes.POST_RESET_PASSWROD_START, postResetPassword);
}
