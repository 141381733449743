export const ActionTypes = {
  GET_LIST_REPORTS_START: 'GET_LIST_REPORTS_START',
  GET_LIST_REPORTS_SUCCESS: 'GET_LIST_REPORTS_SUCCESS',
  GET_LIST_REPORTS_ERROR: 'GET_LIST_REPORTS_ERROR',

  GET_REPORT_FILTER_START: 'GET_REPORT_FILTER_START',
  GET_REPORT_FILTER_SUCCESS: 'GET_REPORT_FILTER_SUCCESS',
  GET_REPORT_FILTER_ERROR: 'GET_REPORT_FILTER_ERROR',

  CHECK_CLIENT_ALLOCATION_START: 'CHECK_CLIENT_ALLOCATION_START',
  CHECK_CLIENT_ALLOCATION_SUCCESS: 'CHECK_CLIENT_ALLOCATION_SUCCESS',
  CHECK_CLIENT_ALLOCATION_ERROR: 'CHECK_CLIENT_ALLOCATION_ERROR',

  ADD_EXNESS_TOKEN_START: 'ADD_EXNESS_TOKEN_START',
  ADD_EXNESS_TOKEN_SUCCESS: 'ADD_EXNESS_TOKEN_SUCCESS',
  ADD_EXNESS_TOKEN_ERROR: 'ADD_EXNESS_TOKEN_ERROR',

  GET_EXNESS_TOKEN_START: 'GET_EXNESS_TOKEN_START',
  GET_EXNESS_TOKEN_SUCCESS: 'GET_EXNESS_TOKEN_SUCCESS',
  GET_EXNESS_TOKEN_ERROR: 'GET_EXNESS_TOKEN_ERROR',
};

export const actions = {
  // get report filter
  getReportFilterStart: (params) => ({
    type: ActionTypes.GET_REPORT_FILTER_START,
    params,
  }),
  getReportFilterSuccess: (payload) => ({
    type: ActionTypes.GET_REPORT_FILTER_SUCCESS,
    payload,
  }),
  getReportFilterError: (payload) => ({
    type: ActionTypes.GET_REPORT_FILTER_ERROR,
    payload,
  }),
  // get list reports
  getListReportsStart: (params) => ({
    type: ActionTypes.GET_LIST_REPORTS_START,
    params,
  }),
  getListReportsSuccess: (payload) => ({
    type: ActionTypes.GET_LIST_REPORTS_SUCCESS,
    payload,
  }),
  getListReportsError: (payload) => ({
    type: ActionTypes.GET_LIST_REPORTS_ERROR,
    payload,
  }),
  // client allocation check
  checkClientAllocationStart: (params) => ({
    type: ActionTypes.CHECK_CLIENT_ALLOCATION_START,
    params,
  }),
  checkClientAllocationSuccess: (payload) => ({
    type: ActionTypes.CHECK_CLIENT_ALLOCATION_SUCCESS,
    payload,
  }),
  checkClientAllocationError: (payload) => ({
    type: ActionTypes.CHECK_CLIENT_ALLOCATION_ERROR,
    payload,
  }),
  // add exness token
  addExnessTokenStart: (params) => ({
    type: ActionTypes.ADD_EXNESS_TOKEN_START,
    params,
  }),
  addExnessTokenSuccess: (payload) => ({
    type: ActionTypes.ADD_EXNESS_TOKEN_SUCCESS,
    payload,
  }),
  addExnessTokenError: (payload) => ({
    type: ActionTypes.ADD_EXNESS_TOKEN_ERROR,
    payload,
  }),
  // get exness token
  getExnessTokenStart: (params) => ({
    type: ActionTypes.GET_EXNESS_TOKEN_START,
    params,
  }),
  getExnessTokenSuccess: (payload) => ({
    type: ActionTypes.GET_EXNESS_TOKEN_SUCCESS,
    payload,
  }),
  getExnessTokenError: (payload) => ({
    type: ActionTypes.GET_EXNESS_TOKEN_ERROR,
    payload,
  }),
};
