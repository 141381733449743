import LoginPage from './pages/Login';
import Report from './pages/Report';

const publicRoutes = [
  {
    title: 'Login',
    path: '/login',
    component: LoginPage,
    exact: true,
  },
];

const privateRoutes = [
  {
    name: 'Home',
    path: '/',
    component: Report,
    exact: true,
  },
  {
    name: 'Report',
    path: '/reports',
    component: Report,
    exact: true,
  },
];

export { publicRoutes, privateRoutes };
