import { put, takeLatest } from 'redux-saga/effects';
import { actions, ActionTypes } from './actions';
import { getReportsClientAPI, getReportFilterAPI, checkClientAllocationAPI, addExnessTokenAPI, getExnessTokenAPI } from '../../services/ReportRequest';

function* getListReports(action) {
  try {
    const response = yield getReportsClientAPI(action.params);
    console.log('response', response);
    if (response?.result) {
      yield put(actions.getListReportsSuccess(response));
    } else {
      yield put(actions.getListReportsError({}));
    }
  } catch (e) {
    yield put(actions.getListReportsError(e));
  }
}

function* getReportFilter(action) {
  try {
    const response = yield getReportFilterAPI(action.params);
    console.log('response', response);
    if (response) {
      yield put(actions.getReportFilterSuccess(response));
    } else {
      yield put(actions.getReportFilterError({}));
    }
  } catch (e) {
    yield put(actions.getReportFilterError(e));
  }
}

function* checkClientAllocation(action) {
  try {
    const response = yield checkClientAllocationAPI(action.params);
    if (response) {
      yield put(actions.checkClientAllocationSuccess(response));
    } else {
      yield put(actions.checkClientAllocationError({}));
    }
  } catch (e) {
    yield put(actions.checkClientAllocationError(e));
  }
}

function* addExnessToken(action) {
  try {
    const response = yield addExnessTokenAPI(action.params);
    console.log('response', response);
    if (response?.data?.result === 1) {
      yield put(actions.addExnessTokenSuccess(response.data));
    } else {
      yield put(actions.addExnessTokenError({}));
    }
  } catch (e) {
    yield put(actions.addExnessTokenError(e));
  }
}

function* getExnessToken(action) {
  try {
    const response = yield getExnessTokenAPI(action.params);
    if (response?.result) {
      yield put(actions.getExnessTokenSuccess(response.listExnessToken));
    } else {
      yield put(actions.getExnessTokenError({}));
    }
  } catch (e) {
    yield put(actions.getExnessTokenError(e));
  }
}

export function* watchReportSaga() {
  yield takeLatest(ActionTypes.GET_LIST_REPORTS_START, getListReports);
  yield takeLatest(ActionTypes.GET_REPORT_FILTER_START, getReportFilter);
  yield takeLatest(ActionTypes.CHECK_CLIENT_ALLOCATION_START, checkClientAllocation);
  yield takeLatest(ActionTypes.ADD_EXNESS_TOKEN_START, addExnessToken);
  yield takeLatest(ActionTypes.GET_EXNESS_TOKEN_START, getExnessToken);
}
