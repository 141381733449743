import { Form, Input, Button, Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../Login/actions';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { getToken } from '../../services/api';
import { onRedirect } from '../../utils';

function Login() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.LoginReducer.adminInfo?.accessToken);

  const onFinish = (values) => {
    dispatch(actions.postLoginStart({
      username: values.username,
      password: values.password,
    }));
  };

  useEffect(() => {
    if (authToken) {
      getToken(authToken);
      onRedirect('/');
    }
  }, [authToken]);

  return (
    <Row className="login">
      <div className="login_container">
        <Row className="login_content-wrapper">
          <Col xs={24}>
            <div className="login-card">
              <h1>{t('login.title_welcome')}</h1>
              <div className="title-login">{t('login.btnLoginTxt').toUpperCase()}</div>
              <Form
                className="login-form"
                initialValues={{ email: '', password: '' }}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item
                  name="username"
                  rules={[{ required: true, message: 'Username or email is required' }]}
                >
                  <Input placeholder={t('login.email')} maxLength={100} />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: 'Password is required' }]}
                >
                  <Input.Password placeholder={t('login.password')} maxLength={20} />
                </Form.Item>
                <Form.Item className="button-login">
                  <Button className="full-width" type="primary" htmlType="submit">
                    {t('login.btnLoginTxt')}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </Row>
  );
}

export default Login;