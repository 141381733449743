import React, { useEffect, useState } from 'react';
import { Button, Table, Input, Select, DatePicker, Divider } from 'antd';
import { SearchOutlined, ClearOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { actions } from '../actions';
import { DEFAULT_INITIAL_PAGE, DEFAULT_PAGE_SIZE } from '../../../common/constant';
import { HOME_TAB } from '../../../common';
import { columnReport } from './columns';
const { Option } = Select;
const { RangePicker } = DatePicker;

const initParams = {
  exnessAccount: null,
  partnerAccountName: [],
  myPartnerAccount: [],
  clientCountry: [],
  clientId: [],
  clientAccount: [],
  clientAccountType: [],
  registrationPeriodFrom: "2007-01-01",
  registrationPeriodTo: moment().format('YYYY-MM-DD'),
  registrationPeriod: null,
  tradingPeriodFrom: null,
  tradingPeriodTo: null,
  tradingPeriod: null,
  comment: null,
  limit: DEFAULT_PAGE_SIZE,
  offset: DEFAULT_INITIAL_PAGE,
};

function Reports({ curTab }) {
  const dispatch = useDispatch();
  const listReport = useSelector((state) => state.ReportReducer?.reports);
  const listExnessToken = useSelector((state) => state.ReportReducer?.listExnessToken);
  const [params, setParams] = useState(initParams);
  const partnerAccountNames = useSelector((state) => state.ReportReducer?.reportFilter?.partner_account_name);
  const myPartnerAccounts = useSelector((state) => state.ReportReducer?.reportFilter?.partner_account);
  const clientCountrys = useSelector((state) => state.ReportReducer?.reportFilter?.country);
  const clientIds = useSelector((state) => state.ReportReducer?.reportFilter?.client_uid);
  const clientAccounts = useSelector((state) => state.ReportReducer?.reportFilter?.client_account);
  const clientAccountTypes = useSelector((state) => state.ReportReducer?.reportFilter?.client_account_type);

  const handlePageChange = (page) => {
    dispatch(actions.getListReportsStart({ ...params, offset: page - 1 }));
    setParams({ ...params, offset: page - 1 });
  };

  const onChangeSelect = (field, value) => {
    setParams({ ...params, [field]: value });
    if (field === 'exnessAccount') {
      onSearch({...params, exnessAccount: value});
      dispatch(actions.getReportFilterStart({exnessAccount: value}));
    }
  };

  const onChangeMultiSelect = (field, value) => {
    setParams({ ...params, [field]: value });
  };

  const handleChangeDate = (field, date, dateString) => {
    setParams({ ...params, [field]: date, [field + 'From']: dateString[0], [field + 'To']: dateString[1] });
  };

  const handleSearchChange = (e) => {
    setParams({ ...params, comment: e.target.value });
  };

  const onClearFilters = () => {
    setParams({...initParams, exnessAccount: params.exnessAccount});
  };

  const onSearch = (requestParams) => {
    const dataRequest = requestParams.exnessAccount ? requestParams : params;
    dispatch(actions.getListReportsStart(dataRequest));
  };

  useEffect(() => {
    if (curTab === HOME_TAB.REPORTS) {
      dispatch(actions.getExnessTokenStart());
      dispatch(actions.getListReportsStart(params));
      dispatch(actions.getReportFilterStart());
      setParams(initParams);
    }
  }, [curTab]);

  return (
    <div className="report-tab">
      <div className='exness-account'>
        <div className='exness-account-title'>Exness Account:</div>
        <div className='exness-account-list'>
          <Select
            value={params.exnessAccount}
            defaultValue={listExnessToken[0]?.value}
            onChange={(value) => onChangeSelect('exnessAccount', value)}
          >
            {listExnessToken?.map((item, index) => (
              <Option key={index} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <Divider />
      <div className="search-panel">
        <div className="search-panel-item">
          <div className="search-panel-item-title">Partner account name</div>
          <Select
            mode="multiple"
            allowClear
            value={params.partnerAccountName}
            onChange={(value) => onChangeMultiSelect('partnerAccountName', value)}
            placeholder="All"
            options={partnerAccountNames}
          />
        </div>
        <div className="search-panel-item">
          <div className="search-panel-item-title">My partner account</div>
          <Select
            mode="multiple"
            allowClear
            value={params.myPartnerAccount}
            onChange={(value) => onChangeMultiSelect('myPartnerAccount', value)}
            placeholder="All"
            options={myPartnerAccounts}
          />
        </div>
        <div className="search-panel-item">
          <div className="search-panel-item-title">Client country</div>
          <Select
            mode="multiple"
            allowClear
            value={params.clientCountry}
            onChange={(value) => onChangeMultiSelect('clientCountry', value)}
            placeholder="All"
            options={clientCountrys}
          />
        </div>
        <div className="search-panel-item">
          <div className="search-panel-item-title">Client ID</div>
          <Select
            mode="multiple"
            allowClear
            value={params.clientId}
            onChange={(value) => onChangeMultiSelect('clientId', value)}
            placeholder="All"
            options={clientIds}
          />
        </div>
        
        <div className="search-panel-item">
          <div className="search-panel-item-title">Client account</div>
          <Select
            mode="multiple"
            allowClear
            value={params.clientAccount}
            onChange={(value) => onChangeMultiSelect('clientAccount', value)}
            placeholder="All"
            options={clientAccounts}
          />
        </div>
        <div className="search-panel-item">
          <div className="search-panel-item-title">Client account type</div>
          <Select
            mode="multiple"
            allowClear
            value={params.clientAccountType}
            onChange={(value) => onChangeMultiSelect('clientAccountType', value)} 
            placeholder="All"
            options={clientAccountTypes}
          />
        </div>
      </div>
      <div className="search-panel">
        <div className="search-panel-item">
          <div className="search-panel-item-title">Account Registration period</div>
          <RangePicker value={params.registrationPeriod} onChange={(date, dateString) => handleChangeDate('registrationPeriod', date, dateString)} />
        </div>
        <div className="search-panel-item">
          <div className="search-panel-item-title">Last Trading period</div>
          <RangePicker value={params.tradingPeriod} onChange={(date, dateString) => handleChangeDate('tradingPeriod', date, dateString)} />
        </div>
        <div className="search-panel-item">
          <div className="search-panel-item-title">Search by comment</div>
          <Input
            addonBefore={<SearchOutlined />} 
            maxLength={255}
            onChange={handleSearchChange}
            value={params.comment} 
          />
        </div>
        <div className='search-panel-item'>
          <Button type='link' icon={<ClearOutlined />} onClick={onClearFilters}>Clear filters</Button>
        </div>
        <div className="search-panel-item">
          <Button className='apply-button' onClick={onSearch}>Apply</Button>
        </div>
      </div>
      <div className="statistical">
        <div className="statistical-item">
          <div className="statistical-item-value">{listReport?.totals?.count || 0}</div>
          <div className="statistical-item-title">Clients</div>
        </div>
        <div className="statistical-item">
          <div className="statistical-item-value">{listReport?.totals?.client_uid_count || 0}</div>
          <div className="statistical-item-title">Clients Account</div>
        </div>
        <div className="statistical-item">
          <div className="statistical-item-value">{listReport?.totals?.volume_lots || 0}</div>
          <div className="statistical-item-title">Volume (lots)</div>
        </div>
        <div className="statistical-item">
          <div className="statistical-item-value">{listReport?.totals?.volume_mln_usd || 0}</div>
          <div className="statistical-item-title">Volume (Min USD)</div>
        </div>
      </div>
      <Table
        bordered
        dataSource={listReport?.data}
        columns={columnReport()}
        className="table"
        pagination={{
          current: params.offset + 1,
          pageSize: params.limit,
          total: listReport?.totals?.count,
          onChange: handlePageChange,
        }}
        scroll={{ x: 1024 }}
      />
    </div>
  );
}
export default Reports;
