import moment from 'moment';
import React from 'react';
import { DATE_FORMAT } from '../../../common';
import { numberWithCommas } from '../../../utils';
export const columnReport = () => [
  {
    title: 'Partner Account Name',
    dataIndex: 'partner_account_name',
    key: 'partner_account_name',
    align: 'center',
    width: '15%',
    ellipsis: true,
  },
  {
    title: 'My partner account',
    dataIndex: 'partner_account',
    key: 'partner_account',
    align: 'center',
    width: '15%',
  },
  {
    title: 'Client Country',
    dataIndex: 'country',
    key: 'country',
    align: 'center',
    width: '10%',
  },
  {
    title: 'Client ID',
    dataIndex: 'client_uid',
    key: 'client_uid',
    align: 'center',
    width: '10%',
  },
  {
    title: 'Client Account',
    dataIndex: 'client_account',
    key: 'client_account',
    align: 'center',
    width: '15%',
  },
  {
    title: 'Client Account Type',
    dataIndex: 'client_account_type',
    key: 'client_account_type',
    align: 'center',
    width: '15%',
  },
  {
    title: 'Account Registration Date',
    dataIndex: 'reg_date',
    key: 'reg_date',
    align: 'center',
    width: '15%',
    render: (value) => {
      return <p>{moment(value).format(DATE_FORMAT.DDMMYY_SEARCH) === 'Invalid date' ? '-' : moment(value).format(DATE_FORMAT.DDMMYY_SEARCH)}</p>
    }
  },
  {
    title: 'Volume',
    dataIndex: 'volume',
    key: 'volume',
    align: 'center',
    width: '15%',
    children: [
      {
        title: 'Lots',
        dataIndex: 'volume_lots',
        key: 'volume_lots',
        align: 'center',
        width: '10%',
      },
      {
        title: 'Mln USD',
        dataIndex: 'volume_mln_usd',
        key: 'volume_mln_usd',
        align: 'center',
        width: '10%',
      }
    ]
  },
  {
    title: 'Last Trading Date',
    dataIndex: 'trade_fn',
    key: 'trade_fn',
    align: 'center',
    width: '15%',
    render: (value) => {
      return <p>{moment(value).format(DATE_FORMAT.DDMMYY_SEARCH) === 'Invalid date' ? '-' : moment(value).format(DATE_FORMAT.DDMMYY_SEARCH)}</p>
    }
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    key: 'comment',
    align: 'center',
    width: '15%',
  },
];