import { api } from '../services/api';

export const getReportFilterAPI = (body) => {
  return api.get(`/users/reports-filters`, body);
};

export const getReportsClientAPI = (body) => {
  return api.get(`/users/reports-clients`, body);
};

export const checkClientAllocationAPI = (body) => {
  return api.get(`/users/client-allocation-check`, body);
};

export const addExnessTokenAPI = (body) => {
  return api.post(`/users/add-token`, body);
};

export const getExnessTokenAPI = (body) => {
  return api.get(`/users/get-token`, body);
};

