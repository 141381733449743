import React, { useState, useEffect } from 'react';
import { Button, Input, Select } from 'antd';
import { actions, ActionTypes } from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
const { Option } = Select;

const initParams = {
  exnessAccount: null,
  email: null,
  result: null
};

function ClientAllocationCheck({ curTab }) {
  const dispatch = useDispatch();

  const [params, setParams] = useState(initParams);
  const state = useSelector((state) => state.ReportReducer?.state);
  const listExnessToken = useSelector((state) => state.ReportReducer?.listExnessToken);
  const clientAllocation = useSelector((state) => state.ReportReducer?.checkClientAllocation);

  useEffect(() => {
    if (state === ActionTypes.CHECK_CLIENT_ALLOCATION_SUCCESS || state === ActionTypes.CHECK_CLIENT_ALLOCATION_ERROR) {
      setParams({ ...params, result: true });
    }
  }, [state]);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setParams({ ...params, email: value });
  };

  const onChangeSelect = (field, value) => {
    setParams({ ...params, [field]: value });
  };

  const onBack = () => {
    setParams({ ...params, result: null });
  };

  const onSearch = () => {
    dispatch(actions.checkClientAllocationStart(params));
  };

  return (
    <div>
      {params.result ? (
        <div className="client-alloaction-check client-alloaction-check-result">
          { clientAllocation?.affiliation ? (
            <div>
              <div className="client-alloaction-check-icon">
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              </div>
              <div className="client-alloaction-check-title">This client is allocated to you.</div>
            </div>
          ) : (
            <div>
              <div className="client-alloaction-check-icon">
                <CloseCircleTwoTone twoToneColor="#ff4d4f" />
              </div>
              <div className="client-alloaction-check-title">This client is not allocated to you.</div>
            </div>
          )}
          <div className="client-alloaction-check-description">
            If you have any questions, please contact our support team or your Account Manager.
          </div>
          <Button type="primary" className="client-alloaction-check-search-button" onClick={onBack}>Back</Button>
        </div>
      ) : (
        <div className="client-alloaction-check">
            <div className="client-alloaction-check-title">Client allocation check</div>
            <div className="client-alloaction-check-description">Here you can check if a client allocated to you under your partner link. To verify a client, please input their email address and click Continue.</div>
            <div className='exness-account'>
              <div className='exness-account-title'>Exness Account:</div>
              <div className='exness-account-list'>
                <Select
                  className='full-width'
                  value={params.exnessAccount}
                  defaultValue={listExnessToken[0]?.value}
                  onChange={(value) => onChangeSelect('exnessAccount', value)}
                >
                  {listExnessToken?.map((item, index) => (
                    <Option key={index} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="client-alloaction-check-search">
              <div className="client-alloaction-check-search-title">Client Email</div>
              <Input allowClear placeholder='email' maxLength={255} onPressEnter={onSearch} onChange={handleSearchChange} />
            </div>
            <Button type="primary" className="client-alloaction-check-search-button" onClick={onSearch}>Continue</Button>
        </div>
      )}
    </div>
  );
}
export default ClientAllocationCheck;
