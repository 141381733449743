import React, { useState } from 'react';
import { Tabs } from 'antd';
import Reports from './component/Reports';
import ClientAllocationCheck from './component/ClientAllocationCheck';
import ExnessTokens from './component/ExnessTokens';
import { HOME_TAB } from '../../common';
import { useSelector } from 'react-redux';
import { USER_ROLE } from '../../common/constant';

const { TabPane } = Tabs;

function Report() {
  const [curTab, setCurTab] = useState(HOME_TAB.REPORTS);
  const userRole = useSelector((state) => state.LoginReducer.adminInfo?.role);

  const HandleChangeTab = (activeKey) => {
    setCurTab(activeKey);
  }

  return (
    <div className="report">
      <Tabs defaultActiveKey={HOME_TAB.EXNESS_TOKENS} activeKey={curTab} className='report__tab' onChange={HandleChangeTab}>
        { userRole === USER_ROLE.ADMIN && (
          <TabPane tab={'Exness Tokens'} key={HOME_TAB.EXNESS_TOKENS}>
            <ExnessTokens />
          </TabPane>
        )}
        <TabPane tab={'Reports'} key={HOME_TAB.REPORTS}>
          <Reports curTab={curTab} />
        </TabPane>
        <TabPane tab={'Client Allocation Check'} key={HOME_TAB.ALLOCTION_CHECK}>
          <ClientAllocationCheck curTab={curTab} />
        </TabPane>
      </Tabs>
    </div>
  );
}
export default Report;
