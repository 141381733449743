import React from "react";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import FlagJpImg from "../resources/images/jp-flag.png";
import FlagENImg from "../resources/images/flagEN.png";
import EyeOpen from "../resources/images/icon_eye";
import EyeClose from "../resources/images/icon_eye_close";
import IconSearch from "../resources/images/icon_search";
import IconPoint from "../resources/images/icon_point";

export const PAGE_SIZE_OPTIONS = [10, 20, 50];

export const STYLE = {
  BLOCK_SMALL_DETAIL: "block detail-small",
};

export const MANAGE_CHANNEL = {
  APPROVE: "approve",
  REJECT: "reject",
  ACTIVE: 1,
  DEACTIVE: 2,
};
export const RECEIVER = {
  USER: "4",
  ACTOR: "3",
  CHANNEL: "2",
};
export const NOTIFICATION_STATUS = {
  UNSENT: 1,
  SENT: 2,
  SCHEDULED: 3,
};

export const META_CODE = {
  SUCCESS: 0,
};

export const MESSAGE = {
  UPDATE_SUCCESS: "message.MSG_02",
  CREATE_SUCCESS: "message.MSG_01",
};

export const LIST_DURATION = [
  {
    value: 'D',
    label: 'Day',
  },
  {
    value: 'W',
    label: 'Week',
  },
  {
    value: 'M',
    label: 'Month',
  },
];

export const HOME_TAB = {
  EXNESS_TOKENS: 'exness-tokens',
  REPORTS: 'reports',
  ALLOCTION_CHECK: 'allocation-check'
}

export const USER_TAB = {
  SINGLE: 'single',
  BUNDLE: 'bundle'
}

export const UPGRADE_STATUSES = {
  FAIL: {
    value: false,
    label: 'Fail',
    bgLabelColor: 'red',
    labelColor: 'red',
  },
  SUCCESSFUL: {
    value: true,
    label: 'Sucessful',
    bgLabelColor: 'green',
    labelColor: 'green',
  },
};

export const REPORT_SALE_DURATION = [
  {
    value: 'THIS_MONTH',
    label: 'This Month',
  },
  {
    value: 'LAST_MONTH',
    label: 'Last Month',
  },
  {
    value: 'THREE_MONTH',
    label: '3 Month Ago',
  },
  {
    value: 'CUSTOM_DATE',
    label: 'Custom',
  },
]

export const ACTIVE_STATUS_LIST = [
  {
    value: 'New',
    label: 'New Upgrade',
  },
  {
    value: 'Extend',
    label: 'Extend Upgrade',
  },
]

export const DATE_FORMAT = {
  DDMMYY_HHMMSS: "DD-MM-YYYY HH:mm:ss",
  DDMMYY_SEARCH: "DD/MM/YYYY",
  HHMMSS: "HH:mm:ss",
};

export const ICON = {
  EYE_OPEN: <EyeOpen />,
  EYE_CLOSE: <EyeClose />,
  SEARCH: <IconSearch />,
  POINT: <IconPoint />,
  SEARCH_TEXT: <SearchOutlined />,
  UPLOAD_ICON: <PlusOutlined />,
};

export const ROLE = {
  ADMIN: 1,
  CHANNEL: 2,
  ACTOR: 3,
  USER: 4,
};

export const ENDPOINT_AUTHENTICATION_SOCKET = `${process.env.REACT_APP_API_URL}/`;
export const TAB_VALUECOIN_WALLET = 0;
export const TAB_MNEMONIC_PHRASE = 1;
export const TAB_MNEMONIC_PHRASE_OPTION_1 = 12;
export const TAB_MNEMONIC_PHRASE_OPTION_2 = 24;
export const COUNT_REMOVE_RANDOM_MNEMONIC = 5;
export const THUMBNAIL_ACCEPT_TYPE =
  "image/png, image/jpg, image/jpeg, image/heic";
export const GIF_ACCEPT_TYPE = "image/gif";
export const LIMIT_IMAGE_SIZE = 5;

export const Language = {
  EN: "en",
  JA: "ja",
  KO: "ko",
  CN: "cn",
};

export const VALUE_KYC_STATUS = {
  ALL: null,
  NEW: 1,
  APPROVE: 3,
  PENDING: 2,
  REJECT: 4,
};

export const VALUE_WITHDRAW_STATUS = {
  ALL: null,
  PENDING: 1,
  REJECT: 2,
  COMPLETED: 3,
};
export const VALUE_NOTIFICATION_STATUS = {
  ALL: null,
  UNSENT: 1,
  SENT: 2,
  SCHEDULED: 3,
};
export const VALUE_TRANSACTION_STATUS = {
  ALL: null,
  INPROCESS: 1,
  COMPLETED: 2,
  FAILED: 3,
};

export const VALUE_CHANNEL_STATUS = {
  ALL: null,
  NEW: 4,
  ACTIVE: 1,
  INACTIVE: 2,
};

export const TEXT_NULL = "";
export const VALUE_PACKAGE_STATUS = {
  ALL: null,
  ACTIVE: 1,
  INACTIVE: 2,
};

export const VALUE_GIFT_STATUS = {
  ALL: null,
  INACTIVE: 0,
  ACTIVE: 1,
};
export const VALUE_USER_STATUS = {
  ALL: null,
  ACTIVE: 1,
  BLOCKED: 3,
};
export const VALUE_ACTOR_STATUS = {
  ALL: null,
  ACTIVE: 1,
  INACTIVE: 2,
  BLOCKED: 3,
};
export const TYPE_OF_ANT_DESIGN = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  IMG_DONE: "done",
};

export const validateIMG = ["image/png", "image/jpeg", "image/heic"];

export const HTTP_RESPONSE = {
  OK: 200,
  ERROR_CODE_401: 401,
};

export const typeExtraInfo = {
  NAME: "name",
  VALUE: "value",
};

export const typeOfAntDesign = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  IMG_DONE: "done",
};

export const PARAMS_TABLE = {
  limit: 10,
  offset: 0,
};

export const objectImageDefault = {
  uid: 1,
  id: 1,
  status: typeOfAntDesign.IMG_DONE,
};

export const DATA_LANGUAGE = [
  {
    lang: "en",
    icon: FlagENImg,
    label: "lang.en",
  },
  {
    lang: "ja",
    icon: FlagJpImg,
    label: "lang.ja",
  },
];

export const STRING_TYPE = "string";

export const BANNER_PC_IMAGE = {
  width: 1112,
  height: 103,
};

export const BANNER_MOBILE_IMAGE = {
  width: 360,
  height: 120,
};

export const IMAGE_TYPE_SUPPORTED = [
  "image/jpg",
  "image/heic",
  "image/jpeg",
  "image/png",
];

export const PHOTO_TYPE = "common.type_image";

export const TYPE_PASSWORD = {
  INPUT_EMAIL: 1,
  FORM_RESET: 2,
  SUCCESS: 3,
};